export function calculateAge(dateOfBirth: Date | null): string {

    if (dateOfBirth == null) return 'birthDate_less_than_18';

    const today = new Date();

    let age = today.getFullYear() - dateOfBirth.getFullYear();


    if (
        today.getMonth() < dateOfBirth.getMonth() ||
        (today.getMonth() === dateOfBirth.getMonth() && today.getDate() < dateOfBirth.getDate())
    ) {
        age--;
    }

    if (
        today.getMonth() > dateOfBirth.getMonth() ||
        (today.getMonth() === dateOfBirth.getMonth() && today.getDate() > dateOfBirth.getDate())
    ) {
        age++;
    }

    if (age >= 18 && age < 50) return 'birthDate_18_to_49';
    if (age >= 50 && age < 55) return 'birthDate_50_to_54';
    if (age >= 55 && age < 60) return 'birthDate_55_to_59';
    if (age >= 60 && age < 65) return 'birthDate_60_to_64';
    if (age >= 65) return 'birthDate_over_65';

    return 'birthDate_less_than_18';
}
