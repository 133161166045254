import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import './i18n';
import { Route, Routes } from 'react-router-dom';
import MainScreen from './components/screens/main-screen/MainScreen';
import FormBirthDate from './components/screens/subscreens/questionnaire/form-birth-date/FormBirthDate';
import {useTranslation} from 'react-i18next';
import QuestionnaireChoice from './components/screens/subscreens/questionnaire-choice/QuestionnaireChoice';
import QandA from './components/screens/subscreens/q-and-a/QandA';
import News from './components/screens/subscreens/news/News';
import Brochures from './components/screens/subscreens/brochures/Brochures';
import About from './components/screens/subscreens/about/About';
import Login from './components/screens/subscreens/questionnaire/login/Login';
import ResultsContext from './store/results-context';
import {ResultsModel} from './model/results.model';
import {v4 as uuidv4} from 'uuid';
import {Profession} from './components/screens/subscreens/questionnaire/login/Profession';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import plPL from 'antd/lib/locale/pl_PL';
import {Locale} from 'antd/es/locale';
import FormCivilizationDiseases
    from './components/screens/subscreens/questionnaire/form-civilization-diseases/FormCivilizationDiseases';
import FormOtherHealthProblems
    from './components/screens/subscreens/questionnaire/form-other-health-problems/FormOtherHealthProblems';
import FormRiskFactors
    from './components/screens/subscreens/questionnaire/form-risk-factors/FormRiskFactors';
import Results from './components/screens/subscreens/questionnaire-results/results/Results';

function App() {

    const {t, i18n} = useTranslation();

    const [locale, setLocale] = useState('pl');
    const [currentLocale, setCurrentLocale] = useState(plPL);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language).then(() => {})
    }

    const changeLocale = (currentLocale: Locale) => {
        setLocale(currentLocale.locale)
        setCurrentLocale(currentLocale);
        dayjs.locale(locale === 'pl' ? 'pl' : 'en');
    }


    const [userId, setUserId] = useState<string>(uuidv4());
    const userIdCached = useMemo(() => ({userId, setUserId}), [userId, setUserId]);
    const [profession, setProfession] = useState<string>(Profession.doctor);
    const [showResultsForProfessionals, setShowResultsForProfessionals] = useState<boolean>(true);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const [pregnancy, setPregnancy] = useState<boolean>(false);
    const [plannedPregnancy, setPlannedPregnancy] = useState<boolean>(false);
    const [circulatorySystemDisease, setCirculatorySystemDisease] = useState<boolean>(false);
    const [diabetes, setDiabetes] = useState<boolean>(false);
    const [chronicRespiratoryDisease, setChronicRespiratoryDisease] = useState<boolean>(false);
    const [chronicKidneyDisease, setChronicKidneyDisease] = useState<boolean>(false);
    const [chronicLiverDisease, setChronicLiverDisease] = useState<boolean>(false);
    const [viralHepatitisC, setViralHepatitisC] = useState<boolean>(false);
    const [depression, setDepression] = useState<boolean>(false);
    const [neoplasticDisease, setNeoplasticDisease] = useState<boolean>(false);
    const [spleenDysfunction, setSpleenDysfunction] = useState<boolean>(false);
    const [immuneDisorders, setImmuneDisorders] = useState<boolean>(false);
    const [immunosuppressiveDrugs, setImmunosuppressiveDrugs] = useState<boolean>(false);
    const [hivInfection, setHivInfection] = useState<boolean>(false);
    const [boneMarrowTransplantation, setBoneMarrowTransplantation] = useState<boolean>(false);
    const [boneMarrowTransplantationBefore, setBoneMarrowTransplantationBefore] = useState<boolean>(false);
    const [boneMarrowTransplantationAfter, setBoneMarrowTransplantationAfter] = useState<boolean>(false);
    const [organTransplantation, setOrganTransplantation] = useState<boolean>(false);
    const [organTransplantationBefore, setOrganTransplantationBefore] = useState<boolean>(false);
    const [organTransplantationAfter, setOrganTransplantationAfter] = useState<boolean>(false);
    const [cochlearImplantation, setCochlearImplantation] = useState<boolean>(false);
    const [cerebroSpinalFluidLeak, setCerebroSpinalFluidLeak] = useState<boolean>(false);
    const [autoimmuneDisease, setAutoimmuneDisease] = useState<boolean>(false);
    const [medicalStudentHealthcare, setMedicalStudentHealthcare] = useState<boolean>(false);
    const [neonatologyPediatricsWorker, setNeonatologyPediatricsWorker] = useState<boolean>(false);
    const [infectiousDiseasesGastroenterologyWorker, setInfectiousDiseasesGastroenterologyWorker] = useState<boolean>(false);
    const [workingWithSevereImmunodeficiency, setWorkingWithSevereImmunodeficiency] = useState<boolean>(false);
    const [policeman, setPoliceman] = useState<boolean>(false);
    const [fireFighter, setFireFighter] = useState<boolean>(false);
    const [borderGuard, setBorderGuard] = useState<boolean>(false);
    const [army, setArmy] = useState<boolean>(false);
    const [healthCareHomeResident, setHealthCareHomeResident] = useState<boolean>(false);
    const [otherMultiPersonPlace, setOtherMultiPersonPlace] = useState<boolean>(false);
    const [outdoorActivity, setOutdoorActivity] = useState<boolean>(false);
    const [hepatitisBExposure, setHepatitisBExposure] = useState<boolean>(false);
    const [cigarettesAlcoholAbuse, setCigarettesAlcoholAbuse] = useState<boolean>(false);
    const [travelingPlans, setTravelingPlans] = useState<boolean>(false);

    const licenceNumberChangeHandler: (value: string) => void = (value: string): void => {
        setLicenseNumber(value);
    }

    const dateOfBirthHandler: (value: Date|null) => void = (value: Date | null): void => {
        setDateOfBirth(value);
    };

    const pregnancyHandler: (value: boolean) => void = (value: boolean): void => {
        setPregnancy(value);
    }

    const plannedPregnancyHandler: (value: boolean) => void = (value: boolean): void => {
        setPlannedPregnancy(value);
    }

    const circulatorySystemDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setCirculatorySystemDisease(value);
    }

    const diabetesHandler: (value: boolean) => void = (value: boolean): void => {
        setDiabetes(value);
    }

    const chronicRespiratoryDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setChronicRespiratoryDisease(value);
    }

    const chronicKidneyDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setChronicKidneyDisease(value);
    }

    const chronicLiverDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setChronicLiverDisease(value);

        if (!value) {
            setViralHepatitisC(false);
        }
    }

    const viralHepatitisCHandler: (value: boolean) => void = (value: boolean): void => {
        setViralHepatitisC(value);
    }

    const depressionHandler: (value: boolean) => void = (value: boolean): void => {
        setDepression(value);
    }

    const neoplasticDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setNeoplasticDisease(value);
    }

    const spleenDysfunctionHandler: (value: boolean) => void = (value: boolean): void => {
        setSpleenDysfunction(value);
    }

    const immuneDisordersHandler: (value: boolean) => void = (value: boolean): void => {
        setImmuneDisorders(value);
    }

    const immunosuppressiveDrugsHandler: (value: boolean) => void = (value: boolean): void => {
        setImmunosuppressiveDrugs(value);
    }

    const hivInfectionHandler: (value: boolean) => void = (value: boolean): void => {
        setHivInfection(value);
    }

    const boneMarrowTransplantationHandler: (value: boolean) => void = (value: boolean): void => {
        setBoneMarrowTransplantation(value);
        setBoneMarrowTransplantationBefore(value);

        if (!value) {
            setBoneMarrowTransplantationAfter(false);
            setBoneMarrowTransplantationBefore(false);
        }
    }

    const boneMarrowTransplantationBeforeHandler: (value: boolean) => void = (value: boolean): void => {
        if (value) {
            setBoneMarrowTransplantationAfter(false);
        }
        setBoneMarrowTransplantationBefore(value);
    }

    const boneMarrowTransplantationAfterHandler: (value: boolean) => void = (value: boolean): void => {
        if (value) {
            setBoneMarrowTransplantationBefore(false);
        }
        setBoneMarrowTransplantationAfter(value);
    }

    const organTransplantationHandler: (value: boolean) => void = (value: boolean): void => {
        setOrganTransplantation(value);
        setOrganTransplantationBefore(value);

        if(!value) {
            setOrganTransplantationAfter(false);
            setOrganTransplantationBefore(false);
        }

    }

    const organTransplantationBeforeHandler: (value: boolean) => void = (value: boolean): void => {
        if (value) {
            setOrganTransplantationAfter(false);
        }
        setOrganTransplantationBefore(value);
    }

    const organTransplantationAfterHandler: (value: boolean) => void = (value: boolean): void => {
        if (value) {
            setOrganTransplantationBefore(false);
        }
        setOrganTransplantationAfter(value);
    }

    const cochlearImplantationHandler: (value: boolean) => void = (value: boolean): void => {
        setCochlearImplantation(value);
    }

    const cerebroSpinalFluidLeakHandler: (value: boolean) => void = (value: boolean): void => {
        setCerebroSpinalFluidLeak(value);
    }

    const autoImmuneDiseaseHandler: (value: boolean) => void = (value: boolean): void => {
        setAutoimmuneDisease(value);
    }

    const medicalStudentHealthcareHandler: (value: boolean) => void = (value: boolean): void => {
        setMedicalStudentHealthcare(value);
    }

    const neonatologyPediatricsWorkerHandler: (value: boolean) => void = (value: boolean): void => {
        setNeonatologyPediatricsWorker(value);
    }

    const infectiousDiseasesGastroenterologyWorkerHandler: (value: boolean) => void = (value: boolean): void => {
        setInfectiousDiseasesGastroenterologyWorker(value);
    }

    const workingWithSevereImmunodeficiencyHandler: (value: boolean) => void = (value: boolean): void => {
        setWorkingWithSevereImmunodeficiency(value);
    }

    const policemanHandler: (value: boolean) => void = (value: boolean): void => {
        setPoliceman(value);
    }

    const fireFighterHandler: (value: boolean) => void = (value: boolean): void => {
        setFireFighter(value);
    }

    const borderGuardHandler: (value: boolean) => void = (value: boolean): void => {
        setBorderGuard(value);
    }

    const armyHandler: (value: boolean) => void = (value: boolean): void => {
        setArmy(value);
    }

    const healthCareHomeResidentHandler: (value: boolean) => void = (value: boolean): void => {
        setHealthCareHomeResident(value);
    }

    const otherMultiPersonPlaceHandler: (value: boolean) => void = (value: boolean): void => {
        setOtherMultiPersonPlace(value);
    }

    const outdoorActivityHandler: (value: boolean) => void = (value: boolean): void => {
        setOutdoorActivity(value);
    }

    const hepatitisBExposureHandler: (value: boolean) => void = (value: boolean): void => {
        setHepatitisBExposure(value);
    }

    const cigarettesAlcoholAbuseHandler: (value: boolean) => void = (value: boolean): void => {
        setCigarettesAlcoholAbuse(value);
    }

    const travelingPlansHandler: (value: boolean) => void = (value: boolean): void => {
        setTravelingPlans(value);
    }

    const professionChangeHandler: (value: string) => void = (value: string): void => {
        setProfession(value);
    }

    const showResultsForProfessionalsHandler: (value: boolean) => void = (value: boolean): void => {
        setShowResultsForProfessionals(value);
    }

    const agreeToTermsChangeHandler: (state: boolean) => void = (state: boolean): void => {
        setAgreeToTerms(state);
    }

    useEffect((): void => {}, [
        licenseNumber,
        agreeToTerms,
        profession,
        pregnancy,
        plannedPregnancy
    ]);

    const resultsCtx: ResultsModel = {
        userId: userIdCached.userId,
        profession: profession,
        onProfessionChange: professionChangeHandler,
        showResultsForProfessionals: showResultsForProfessionals,
        onShowResultsForProfessionals: showResultsForProfessionalsHandler,
        agreeToTerms: agreeToTerms,
        onAgreeToTermsChange: agreeToTermsChangeHandler,
        licenseNumber: licenseNumber,
        onLicenseNumberChange: licenceNumberChangeHandler,
        dateOfBirth: dateOfBirth,
        onDateOfBirthChange: dateOfBirthHandler,
        pregnancy: pregnancy,
        onPregnancyChange: pregnancyHandler,
        plannedPregnancy: plannedPregnancy,
        onPlannedPregnancyClick: plannedPregnancyHandler,
        circulatorySystemDisease: circulatorySystemDisease,
        onCirculatorySystemDisease: circulatorySystemDiseaseHandler,
        diabetes: diabetes,
        onDiabetes: diabetesHandler,
        chronicRespiratoryDisease: chronicRespiratoryDisease,
        onChronicRespiratoryDisease: chronicRespiratoryDiseaseHandler,
        chronicKidneyDisease: chronicKidneyDisease,
        onChronicKidneyDisease: chronicKidneyDiseaseHandler,
        chronicLiverDisease: chronicLiverDisease,
        onChronicLiverDisease: chronicLiverDiseaseHandler,
        viralHepatitisC: viralHepatitisC,
        onViralHepatitisC: viralHepatitisCHandler,
        depression: depression,
        onDepression: depressionHandler,
        neoplasticDisease: neoplasticDisease,
        onNeoplasticDisease: neoplasticDiseaseHandler,
        spleenDysfunction: spleenDysfunction,
        onSpleenDysfunction: spleenDysfunctionHandler,
        immuneDisorders: immuneDisorders,
        onImmuneDisorders: immuneDisordersHandler,
        immunosuppressiveDrugs: immunosuppressiveDrugs,
        onImmunosuppressiveDrugs: immunosuppressiveDrugsHandler,
        hivInfection: hivInfection,
        onHivInfection: hivInfectionHandler,
        boneMarrowTransplantation: boneMarrowTransplantation,
        onBoneMarrowTransplantation: boneMarrowTransplantationHandler,
        boneMarrowTransplantationBefore: boneMarrowTransplantationBefore,
        onBoneMarrowTransplantationBefore: boneMarrowTransplantationBeforeHandler,
        boneMarrowTransplantationAfter: boneMarrowTransplantationAfter,
        onBoneMarrowTransplantationAfter: boneMarrowTransplantationAfterHandler,
        organTransplantation: organTransplantation,
        onOrganTransplantation: organTransplantationHandler,
        organTransplantationBefore: organTransplantationBefore,
        onOrganTransplantationBefore: organTransplantationBeforeHandler,
        organTransplantationAfter: organTransplantationAfter,
        onOrganTransplantationAfter: organTransplantationAfterHandler,
        cochlearImplantation: cochlearImplantation,
        onCochlearImplantation: cochlearImplantationHandler,
        cerebroSpinalFluidLeak: cerebroSpinalFluidLeak,
        onCerebroSpinalFluidLeak: cerebroSpinalFluidLeakHandler,
        autoimmuneDisease: autoimmuneDisease,
        onAutoimmuneDisease: autoImmuneDiseaseHandler,
        medicalStudentHealthcare: medicalStudentHealthcare,
        onMedicalStudentHealthcare:  medicalStudentHealthcareHandler,
        neonatologyPediatricsWorker: neonatologyPediatricsWorker,
        onNeonatologyPediatricsWorker:  neonatologyPediatricsWorkerHandler,
        infectiousDiseasesGastroenterologyWorker: infectiousDiseasesGastroenterologyWorker,
        onInfectiousDiseasesGastroenterologyWorker:  infectiousDiseasesGastroenterologyWorkerHandler,
        workingWithSevereImmunodeficiency: workingWithSevereImmunodeficiency,
        onWorkingWithSevereImmunodeficiency: workingWithSevereImmunodeficiencyHandler,
        policeman: policeman,
        onPoliceman:  policemanHandler,
        fireFighter: fireFighter,
        onFireFighter:  fireFighterHandler,
        borderGuard: borderGuard,
        onBorderGuard:  borderGuardHandler,
        army: army,
        onArmy: armyHandler,
        healthCareHomeResident: healthCareHomeResident,
        onHealthCareHomeResident:  healthCareHomeResidentHandler,
        otherMultiPersonPlace: otherMultiPersonPlace,
        onOtherMultiPersonPlace:  otherMultiPersonPlaceHandler,
        outdoorActivity: outdoorActivity,
        onOutdoorActivity:  outdoorActivityHandler,
        hepatitisBExposure: hepatitisBExposure,
        onHepatitisBExposure:  hepatitisBExposureHandler,
        cigarettesAlcoholAbuse: cigarettesAlcoholAbuse,
        onCigarettesAlcoholAbuse:  cigarettesAlcoholAbuseHandler,
        travelingPlans: travelingPlans,
        onTravelingPlans:  travelingPlansHandler
    };

    return (
        <ResultsContext.Provider value={resultsCtx}>
            <Routes>
                <Route path="/" element={<MainScreen t={t} changeLanguage={changeLanguage} changeCurrentLocale={changeLocale} />}>
                    <Route index element={<QuestionnaireChoice t={t} />} />
                    <Route path="login" element={<Login t={t} />} />
                    <Route path="birth-date" element={<FormBirthDate t={t} currentLocale={currentLocale} />} />
                    <Route path="civilization-diseases" element={<FormCivilizationDiseases t={t} />} />
                    <Route path="other-health-problems" element={<FormOtherHealthProblems t={t} />} />
                    <Route path="risk-factors" element={<FormRiskFactors t={t} />} />
                    <Route path="about" element={<About t={t} />} />
                    <Route path="brochures" element={<Brochures t={t} />} />
                    <Route path="qanda" element={<QandA t={t} />} />
                    <Route path="news" element={<News t={t} />} />
                    <Route path="results" element={<Results t={t} />} />
                </Route>
            </Routes>
        </ResultsContext.Provider>
    );
}

export default App;
