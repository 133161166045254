import {VaccineModel} from '../../model/vaccine.model';

export const vaccinationsData: Record<string, VaccineModel> = {
  C: {
    abbreviation: "C",
    fullName: "vaccinations_C",
    disclaimer: "vaccinations_C_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  GR:  {
    abbreviation: "GR",
    fullName: "vaccinations_GR",
    disclaimer: "vaccinations_GR_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  GR_HD:  {
    abbreviation: "GR_HD",
    fullName: "vaccinations_GR_HD",
    disclaimer: "vaccinations_GR_HD_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  RSV:  {
    abbreviation: "RSV",
    fullName: "vaccinations_RSV",
    disclaimer: "vaccinations_RSV_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  NM:  {
    abbreviation: "NM",
    fullName: "vaccinations_NM",
    disclaimer: "vaccinations_NM_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  SP:  {
    abbreviation: "SP",
    fullName: "vaccinations_SP",
    disclaimer: "vaccinations_SP_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  HiB:  {
    abbreviation: "HiB",
    fullName: "vaccinations_HiB",
    disclaimer: "vaccinations_HiB_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  KZM:  {
    abbreviation: "KZM",
    fullName: "vaccinations_KZM",
    disclaimer: "vaccinations_KZM_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  WA:  {
    abbreviation: "WA",
    fullName: "vaccinations_WA",
    disclaimer: "vaccinations_WA_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  WB:  {
    abbreviation: "WB",
    fullName: "vaccinations_WB",
    disclaimer: "vaccinations_WB_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  MMR:  {
    abbreviation: "MMR",
    fullName: "vaccinations_MMR",
    disclaimer: "vaccinations_MMR_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  DTP:  {
    abbreviation: "DTP",
    fullName: "vaccinations_DTP",
    disclaimer: "vaccinations_DTP_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  HPV:  {
    abbreviation: "HPV",
    fullName: "vaccinations_HPV",
    disclaimer: "vaccinations_HPV_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  BCG:  {
    abbreviation: "BCG",
    fullName: "vaccinations_BCG",
    disclaimer: "vaccinations_BCG_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  OW:  {
    abbreviation: "OW",
    fullName: "vaccinations_OW",
    disclaimer: "vaccinations_OW_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  HZV:  {
    abbreviation: "HZV",
    fullName: "vaccinations_HZV",
    disclaimer: "vaccinations_HZV_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  PO:  {
    abbreviation: "PO",
    fullName: "vaccinations_PO",
    disclaimer: "vaccinations_PO_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  ZG:  {
    abbreviation: "ZG",
    fullName: "vaccinations_ZG",
    disclaimer: "vaccinations_ZG_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  JZM:  {
    abbreviation: "JZM",
    fullName: "vaccinations_JZM",
    disclaimer: "vaccinations_JZM_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  WS:  {
    abbreviation: "WS",
    fullName: "vaccinations_WS",
    disclaimer: "vaccinations_WS_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  DB:  {
    abbreviation: "DB",
    fullName: "vaccinations_DB",
    disclaimer: "vaccinations_DB_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  CH:  {
    abbreviation: "CH",
    fullName: "vaccinations_CH",
    disclaimer: "vaccinations_CH_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  RSVc:  {
    abbreviation: "RSVc",
    fullName: "vaccinations_RSVc",
    disclaimer: "vaccinations_RSVc_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  DTPc:  {
    abbreviation: "DTPc",
    fullName: "vaccinations_DTPc",
    disclaimer: "vaccinations_DTPc_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  },
  GRc:  {
    abbreviation: "GRc",
    fullName: "vaccinations_GRc",
    disclaimer: "vaccinations_GRc_disclaimer",
    leaflet: "",
    chpl: "",
    vaccinationSchema: ""
  }
}
