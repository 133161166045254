import {VaccineModel} from '../model/vaccine.model';

export function subtractRepeatedVaccinations(vaccinationsToSubtractFrom: VaccineModel[],
                                             vaccinationsToRemove: VaccineModel[]): VaccineModel[] {
    
    const vaccinationsToRemoveAbbreviations = new Set(vaccinationsToRemove.map(vaccine => vaccine.abbreviation));

    vaccinationsToSubtractFrom = vaccinationsToSubtractFrom.filter(
        vaccine => !vaccinationsToRemoveAbbreviations.has(vaccine.abbreviation)
    );

    return vaccinationsToSubtractFrom;
}
