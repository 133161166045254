import {VaccineModel} from '../model/vaccine.model';

export function removeContraindicatedVaccinations(vaccinationsToRemoveFrom: VaccineModel[],
                                                  vaccinationsToBeRemoved: VaccineModel[]): VaccineModel[] {

    const contraindicated = new Set(vaccinationsToBeRemoved.map(vaccine => vaccine.abbreviation));

    return vaccinationsToRemoveFrom.filter(
        vaccine => !contraindicated.has(vaccine.abbreviation)
    );
}
