import React, {useContext, useState} from 'react';
import './results.css';
import {useMergedResults} from '../../../../../hooks/useMergedResults';
import VaccinationsBar from '../vaccinations-bar/VaccinationsBar';
import {VaccineModel} from '../../../../../model/vaccine.model';
import ButtonComponent from '../../../../shared/button/Button.component';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import { Download, ExternalLink, X } from 'lucide-react';
import VaccinationsBarProfessionals from '../vaccinations-bar-professionals/VaccinationsBarProfessionals';
import ResultsContext from '../../../../../store/results-context';
import {ResultsModel} from '../../../../../model/results.model';

interface ResultsProps {
    t: (key: string) => string;
}

const Results: React.FC<ResultsProps> = ({t}: ResultsProps) => {

    const navigate: NavigateFunction = useNavigate();

    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const [disclaimerIsVisible, setDisclaimerIsVisible] = useState(true);

    const showResultsForProfessionals: boolean =
        localStorage.getItem('loginButtonClicked') === 'true' && resultsCtx.showResultsForProfessionals;

    const handleCloseDisclaimer: () => void = (): void => {
        setDisclaimerIsVisible(false);
    };

    let finalVaccinations: {
        mandatoryVaccinations: VaccineModel[],
        recommendedVaccinations: VaccineModel[],
        highlyRecommendedVaccinations: VaccineModel[],
        contraindicatedVaccinations: VaccineModel[]
    } = useMergedResults();

    const showResults: boolean =
        finalVaccinations.mandatoryVaccinations.length > 0 ||
        finalVaccinations.highlyRecommendedVaccinations.length > 0 ||
        finalVaccinations.recommendedVaccinations.length > 0 ||
        finalVaccinations.contraindicatedVaccinations.length > 0;

    const goBackButtonText: string = t('go_back_button');

    const goBackButtonHandler: () => void = (): void => {
        navigate('/risk-factors');
    }

    return (
        <div className="results__main-wrapper">

            <div className="results__go-back__wrapper">
                <ButtonComponent id="goBackButton"
                                 value={goBackButtonText}
                                 className="results_go-back__button"
                                 onClick={goBackButtonHandler}/>
            </div>

            {
                !showResults &&
                <div>
                    {t('no_results_info')}
                </div>
            }

            {
                showResults &&
                <div className="results__main-results__wrapper">
                    {
                        disclaimerIsVisible &&
                        <div className="results_disclaimer__wrapper">
                            <div className="results_disclaimer-x__wrapper">
                                <X onClick={handleCloseDisclaimer}/>
                            </div>
                            <p>{showResultsForProfessionals ? t('professionals_disclaimer_1') : t('patients_disclaimer_1')}</p>
                            <p>{showResultsForProfessionals ? t('professionals_disclaimer_2') : t('patients_disclaimer_2')}</p>
                            <p>{showResultsForProfessionals ? t('professionals_disclaimer_3') : t('patients_disclaimer_3')}</p>
                        </div>
                    }

                    <div className="results_inner__wrapper">

                        <div className="results_title__wrapper">
                            {t('results')}
                        </div>

                        {
                            finalVaccinations.mandatoryVaccinations.length > 0 &&
                            <div className="results_vaccinations-bar__wrapper mandatory-background">
                                {
                                    showResultsForProfessionals ?
                                        <VaccinationsBarProfessionals t={t}
                                                                      vaccinations={finalVaccinations.mandatoryVaccinations}
                                                                      vaccinationType="mandatory" /> :
                                        <VaccinationsBar t={t}
                                                         vaccinations={finalVaccinations.mandatoryVaccinations}
                                                         vaccinationType="mandatory"/>

                                }
                            </div>
                        }

                        {
                            finalVaccinations.highlyRecommendedVaccinations.length > 0 &&
                            <div className="results_vaccinations-bar__wrapper highly-recommended-background">
                                {
                                    showResultsForProfessionals ?
                                        <VaccinationsBarProfessionals t={t}
                                                                      vaccinations={finalVaccinations.highlyRecommendedVaccinations}
                                                                      vaccinationType="highly_recommended" /> :
                                        <VaccinationsBar t={t}
                                                         vaccinations={finalVaccinations.highlyRecommendedVaccinations}
                                                         vaccinationType="highly_recommended"/>
                                }
                            </div>
                        }

                        {
                            finalVaccinations.recommendedVaccinations.length > 0 &&
                            <div className="results_vaccinations-bar__wrapper recommended-background">
                                {
                                    showResultsForProfessionals ?
                                        <VaccinationsBarProfessionals t={t}
                                                                      vaccinations={finalVaccinations.recommendedVaccinations}
                                                                      vaccinationType="recommended" /> :
                                        <VaccinationsBar t={t}
                                                         vaccinations={finalVaccinations.recommendedVaccinations}
                                                         vaccinationType="recommended"/>

                                }
                            </div>
                        }

                        {
                            finalVaccinations.contraindicatedVaccinations.length > 0 &&
                            <div className="results_vaccinations-bar__wrapper contraindicated-background">
                                {
                                    showResultsForProfessionals ?
                                        <VaccinationsBarProfessionals t={t}
                                                                      vaccinations={finalVaccinations.contraindicatedVaccinations}
                                                                      vaccinationType="contraindicated" /> :
                                        <VaccinationsBar t={t}
                                                         vaccinations={finalVaccinations.contraindicatedVaccinations}
                                                         vaccinationType="contraindicated"/>
                                }
                            </div>
                        }

                        <div className="results_actions__wrapper">

                            <div className="results_actions-print__wrapper">
                                <div className="results_actions-button__wrapper">
                                    {t('pdf_full_version')} <Download size={18}/>
                                </div>
                                <div className="results_actions-button__wrapper">
                                    {t('pdf_shortened_version')} <Download size={18}/>
                                </div>
                            </div>

                            <div className="results_actions-bibliography__wrapper">
                                <div className="results_actions-button__wrapper bibliography__button">
                                    {t('bibliography')} <ExternalLink size={18}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }

        </div>
    )

}

export default Results;
