import { VaccineModel } from '../model/vaccine.model';
import { vaccinationsData } from '../assets/data/vaccinations.data';
import { TFunction } from 'i18next';

export const mapVaccinations = (codes: string[], t: TFunction): VaccineModel[] => {

    return codes.map(code => {
        const showDisclaimer = code.includes('*');
        const cleanCode = code.replace('*', '');
        const vaccine = vaccinationsData[cleanCode];

        if (!vaccine) {
            return {
                abbreviation: code,
                fullName: code,
                disclaimer: "",
                leaflet: "",
                chpl: "",
                vaccinationSchema: "",
                showDisclaimer: showDisclaimer
            };
        }

        return {
            ...vaccine,
            fullName: t(vaccine.fullName, vaccine.fullName),
            disclaimer: t(vaccine.disclaimer, vaccine.disclaimer),
            showDisclaimer: showDisclaimer
        };
    });
};
