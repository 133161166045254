import React, {useContext} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import './formriskfactors.css';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import ResultsContext from '../../../../../store/results-context';
import ButtonComponent from '../../../../shared/button/Button.component';
import CheckboxComponent from '../../../../shared/checkbox/Checkbox.component';
import {ResultsModel} from '../../../../../model/results.model';


interface FormRiskFactorsProps {
    t: (key: string) => string;
}

const FormRiskFactors: React.FC<FormRiskFactorsProps> = ({t}: FormRiskFactorsProps) => {

    const navigate: NavigateFunction = useNavigate();
    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const goNextButtonText: string = t('go_next_button');
    const goBackButtonText: string = t('go_back_button');


    const goBackButtonHandler: () => void = (): void => {
        navigate('/other-health-problems');
    }

    const goNextButtonHandler: () => void = (): void => {
        navigate('/results');
    }

    return (

        <div className="form-civilization-diseases__wrapper">


            <div className="civilization-diseases__inner-wrapper">

                <div className="civilization-diseases_label-input__wrapper">

                    <div className="civilization-diseases_header__wrapper">
                        {t('screen_risk_factors')}
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        {t('healthcare_worker_question')}
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="medicalStudentHealthcare"
                            useLightColor={true}
                            value="medicalStudentHealthcare"
                            textValue={t('medical_student_healthcare')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onMedicalStudentHealthcare(value);
                            }}
                            checked={resultsCtx.medicalStudentHealthcare}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="neonatologyPediatricsWorker"
                            useLightColor={true}
                            value="neonatologyPediatricsWorker"
                            textValue={t('neonatology_pediatrics_worker')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onNeonatologyPediatricsWorker(value);
                            }}
                            checked={resultsCtx.neonatologyPediatricsWorker}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="infectiousDiseasesGastroenterologyWorker"
                            useLightColor={true}
                            value="infectiousDiseasesGastroenterologyWorker"
                            textValue={t('infectious_diseases_gastroenterology_worker')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onInfectiousDiseasesGastroenterologyWorker(value);
                            }}
                            checked={resultsCtx.infectiousDiseasesGastroenterologyWorker}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="workingWithSevereImmunodeficiency"
                            useLightColor={true}
                            value="workingWithSevereImmunodeficiency"
                            textValue={t('working_with_severe_immunodeficiency')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onWorkingWithSevereImmunodeficiency(value);
                            }}
                            checked={resultsCtx.workingWithSevereImmunodeficiency}
                        />
                    </div>


                    <div className="civilization-diseases-checkbox__wrapper">
                        {t('public_official_worker')}
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="policeman"
                            useLightColor={true}
                            value="policeman"
                            textValue={t('policeman')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onPoliceman(value);
                            }}
                            checked={resultsCtx.policeman}
                        />
                    </div>


                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="fireFighter"
                            useLightColor={true}
                            value="fireFighter"
                            textValue={t('fire_fighter')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onFireFighter(value);
                            }}
                            checked={resultsCtx.fireFighter}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="borderGuard"
                            useLightColor={true}
                            value="borderGuard"
                            textValue={t('border_guard')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onBorderGuard(value);
                            }}
                            checked={resultsCtx.borderGuard}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="army"
                            useLightColor={true}
                            value="army"
                            textValue={t('army')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onArmy(value);
                            }}
                            checked={resultsCtx.army}
                        />
                    </div>


                    <div className="civilization-diseases-checkbox__wrapper">
                        {t('people_gather_question')}
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="healthCareHomeResident"
                            useLightColor={true}
                            value="healthCareHomeResident"
                            textValue={t('health_care_home_resident')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onHealthCareHomeResident(value);
                            }}
                            checked={resultsCtx.healthCareHomeResident}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                        <CheckboxComponent
                            id="otherMultiPersonPlace"
                            useLightColor={true}
                            value="otherMultiPersonPlace"
                            textValue={t('other_multi_person_place')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onOtherMultiPersonPlace(value);
                            }}
                            checked={resultsCtx.otherMultiPersonPlace}
                        />
                    </div>


                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="outdoorActivity"
                            useLightColor={true}
                            value="outdoorActivity"
                            textValue={t('outdoor_activity')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onOutdoorActivity(value);
                            }}
                            checked={resultsCtx.outdoorActivity}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="hepatitisBExposure"
                            useLightColor={true}
                            value="hepatitisBExposure"
                            textValue={t('hepatitis_B_exposure')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onHepatitisBExposure(value);
                            }}
                            checked={resultsCtx.hepatitisBExposure}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="cigarettesAlcoholAbuse"
                            useLightColor={true}
                            value="cigarettesAlcoholAbuse"
                            textValue={t('cigarettes_alcohol_abuse')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onCigarettesAlcoholAbuse(value);
                            }}
                            checked={resultsCtx.cigarettesAlcoholAbuse}
                        />
                    </div>
                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="travelingPlans"
                            useLightColor={true}
                            value="travelingPlans"
                            textValue={t('traveling_plans')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onTravelingPlans(value);
                            }}
                            checked={resultsCtx.travelingPlans}
                        />
                    </div>


                </div>


                <div className="navigation-buttons__wrapper">

                    <ButtonComponent id="goBackButton"
                                     value={goBackButtonText}
                                     className="navigation-button"
                                     onClick={goBackButtonHandler}/>

                    <ButtonComponent id="goNextButton"
                                     value={goNextButtonText}
                                     className="navigation-button"
                                     onClick={goNextButtonHandler}/>


                </div>

            </div>
        </div>
    )
}

export default FormRiskFactors;
