import React from 'react';
import './vaccinationsbar.css';
import { VaccineModel } from '../../../../../model/vaccine.model';
import { ExternalLink } from 'lucide-react';

interface VaccinationsBarProps {
    t: (key: string) => string;
    vaccinations: VaccineModel[];
    vaccinationType: string;
    className?: string;
}

const VaccinationsBar: React.FC<VaccinationsBarProps> = ({ t, vaccinations, vaccinationType }: VaccinationsBarProps) => {

    return (
        <div className="vaccinations-bar__wrapper">

            <div className="vaccinations-bar__title">
                {t(vaccinationType)}
            </div>

            <div className="vaccinations-bar__inner-wrapper">

                <div className="vaccinations-bar_single-vaccine__wrapper">
                    {vaccinations.map((vaccine) => (

                        <div key={vaccine.abbreviation} className="vaccinations-bar_single-vaccine">

                            <div className="vaccinations-bar_name">
                                {vaccine.fullName}
                            </div>

                            <div className="vaccinations-bar_link">
                                <a href="https://google.com"
                                   target="_blank"
                                   className="leaflet__link"
                                   rel="noreferrer">
                                    {t('leaflet_link')} <ExternalLink size={12}/>
                                </a>
                            </div>

                            {
                                vaccine.showDisclaimer &&
                                <div className="vaccinations-bar_disclaimer">
                                    {t(`${vaccine.disclaimer}`)}
                                </div>
                            }

                        </div>
                    ))}
                </div>
            </div>

        </div>
    )

}

export default VaccinationsBar;
