import React, {useContext} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import './formcivilizationdiseases.css';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import ResultsContext from '../../../../../store/results-context';
import ButtonComponent from '../../../../shared/button/Button.component';
import CheckboxComponent from '../../../../shared/checkbox/Checkbox.component';
import {ResultsModel} from '../../../../../model/results.model';


interface FormCivilizationDiseasesProps {
    t: (key: string) => string;
}

const FormCivilizationDiseases: React.FC<FormCivilizationDiseasesProps> = ({t}: FormCivilizationDiseasesProps) => {

    const navigate: NavigateFunction = useNavigate();
    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const goNextButtonText: string = t('go_next_button');
    const goBackButtonText: string = t('go_back_button');


    const goBackButtonHandler: () => void = (): void => {
        navigate('/birth-date');
    }

    const goNextButtonHandler: () => void = (): void => {
        navigate('/other-health-problems');
    }

    return (

        <div className="form-civilization-diseases__wrapper">


            <div className="civilization-diseases__inner-wrapper">


                <div className="civilization-diseases_label-input__wrapper">

                    <div className="civilization-diseases_header__wrapper">
                        {t('screen_civilization_diseases')}
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="circulatorySystemDisease"
                            useLightColor={true}
                            value={"circulatorySystemDisease"}
                            textValue={t('circulatory_system_disease')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onCirculatorySystemDisease(value);
                            }}
                            checked={resultsCtx.circulatorySystemDisease}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="diabetes"
                            useLightColor={true}
                            value="diabetes"
                            textValue={t('diabetes')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onDiabetes(value);
                            }}
                            checked={resultsCtx.diabetes}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="chronicRespiratoryDisease"
                            useLightColor={true}
                            value="chronicRespiratoryDisease"
                            textValue={t('chronic_respiratory_disease')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onChronicRespiratoryDisease(value);
                            }}
                            checked={resultsCtx.chronicRespiratoryDisease}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="chronicKidneyDisease"
                            useLightColor={true}
                            value="chronicKidneyDisease"
                            textValue={t('chronic_kidney_disease')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onChronicKidneyDisease(value);
                            }}
                            checked={resultsCtx.chronicKidneyDisease}
                        />
                    </div>

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="chronicLiverDisease"
                            useLightColor={true}
                            value="chronicLiverDisease"
                            textValue={t('chronic_liver_disease')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onChronicLiverDisease(value);
                            }}
                            checked={resultsCtx.chronicLiverDisease}
                        />
                    </div>

                    {resultsCtx.chronicLiverDisease &&
                        <div className="civilization-diseases-checkbox__wrapper additional-padding-left-40">
                            <CheckboxComponent
                                id="viralHepatitisC"
                                useLightColor={true}
                                value="viralHepatitisC"
                                textValue={t('viral_hepatitis_c')}
                                onCheckHandler={(value: boolean) => {
                                    resultsCtx.onViralHepatitisC(value);
                                }}
                                checked={resultsCtx.viralHepatitisC}
                            />
                        </div>
                    }

                    <div className="civilization-diseases-checkbox__wrapper">
                        <CheckboxComponent
                            id="depression"
                            useLightColor={true}
                            value="depression"
                            textValue={t('depression')}
                            onCheckHandler={(value: boolean) => {
                                resultsCtx.onDepression(value);
                            }}
                            checked={resultsCtx.depression}
                        />
                    </div>
                </div>

                <div className="navigation-buttons__wrapper">

                <ButtonComponent id="goBackButton"
                                 value={goBackButtonText}
                                 className="navigation-button"
                                 onClick={goBackButtonHandler}/>

                <ButtonComponent id="goNextButton"
                                 value={goNextButtonText}
                                 className="navigation-button"
                                 onClick={goNextButtonHandler}/>


            </div>

            </div>
        </div>
    )
}

export default FormCivilizationDiseases;
