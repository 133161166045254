import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronUp, ChevronDown } from 'lucide-react';
import SelectButton from '../selectbutton/SelectButton.component';
import './select.css';

type SelectProps = {
    value: string;
    showChooseOption: boolean;
    onValueChange: (value: any) => void;
    data: string[];
};

const SelectComponent = (props: SelectProps) => {
    return (
        <SelectPrimitive.Root value={props.value !== 'no-option' ? props.value : 'no-option'} onValueChange={props.onValueChange}>
            <SelectPrimitive.Trigger className="select-trigger" asChild>
                <SelectButton>
                    <SelectPrimitive.Value/>
                </SelectButton>
            </SelectPrimitive.Trigger>
            <SelectPrimitive.Content className="select-content">
                <SelectPrimitive.ScrollUpButton className="select-scroll-button">
                    <ChevronUp size={12} />
                </SelectPrimitive.ScrollUpButton>
                <SelectPrimitive.Viewport className="select-viewport">

                    {props.showChooseOption &&
                        <SelectPrimitive.Group>
                            <SelectPrimitive.Item
                                key="no-option"
                                value="no-option"
                                disabled={true}
                                className="default-option">
                                <SelectPrimitive.ItemText>-- wybierz --</SelectPrimitive.ItemText>
                            </SelectPrimitive.Item>
                        </SelectPrimitive.Group>
                    }

                    {props.showChooseOption &&
                        <SelectPrimitive.Separator className="select-separator" />
                    }

                    <SelectPrimitive.Group>

                        {props.data.map(
                            (f, i) => (
                                <SelectPrimitive.Item
                                    key={`${f}-${i}`}
                                    value={f}
                                    disabled={f === "1 dzień"}
                                    className="select-item">
                                    <SelectPrimitive.ItemText>{f}</SelectPrimitive.ItemText>
                                    <SelectPrimitive.ItemIndicator className="select-item-indicator">
                                        <Check size={12} />
                                    </SelectPrimitive.ItemIndicator>
                                </SelectPrimitive.Item>
                            )
                        )}
                    </SelectPrimitive.Group>

                </SelectPrimitive.Viewport>
                <SelectPrimitive.ScrollDownButton className="select-scroll-button">
                    <ChevronDown size={12} />
                </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
        </SelectPrimitive.Root>
    );
};

export { SelectComponent };
