import factorsVaccinationsDataX from '../assets/data/factors-vaccinations.data';
import { VaccineModel } from '../model/vaccine.model';
import { TFunction } from 'i18next';

export function addVaccinations(
    mandatoryVaccinations: VaccineModel[],
    recommendedVaccinations: VaccineModel[],
    highlyRecommendedVaccinations: VaccineModel[],
    contraindicatedVaccinations: VaccineModel[],
    name: string,
    t: TFunction
): void {
    const factorsVaccinationsData = factorsVaccinationsDataX(t);

    if (!factorsVaccinationsData[name]) {
        return;
    }

    mandatoryVaccinations.push(...factorsVaccinationsData[name].mandatoryVaccinations);
    recommendedVaccinations.push(...factorsVaccinationsData[name].recommendedVaccinations);
    highlyRecommendedVaccinations.push(...factorsVaccinationsData[name].highlyRecommendedVaccinations);
    contraindicatedVaccinations.push(...factorsVaccinationsData[name].contraindicatedVaccinations);
}
