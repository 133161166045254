import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import ResultsContext from '../../../../../store/results-context';
import {prepareLicenseNumberFormat} from '../../../../../utils/prepare-license-number-format';
import {validateDoctorsMedicalLicenseNumber} from '../../../../../utils/validate-doctors-medical-license-number';
import {validateNursesMedicalLicenseNumber} from '../../../../../utils/validate-nurses-medical-license-number';
import {validatePharmacistsLicenseNumber} from '../../../../../utils/validate-pharmacists-license-number';
import MedicalLicenseNumberInput from '../../../../shared/medical-license-number/MedicalLicenseNumberInput';
import {stringNotNull} from '../../../../../utils/string-null-check';
import {SelectComponent} from '../../../../shared/select/Select.component';
import CheckboxComponent from '../../../../shared/checkbox/Checkbox.component';
import ButtonComponent from '../../../../shared/button/Button.component';
import './login.css';
import {Profession} from './Profession';
import {ResultsModel} from '../../../../../model/results.model';


interface LoginProps {
    t: (key: string) => string;
}

const Login: React.FC<LoginProps> = ({t}: LoginProps) => {

    const navigate: NavigateFunction = useNavigate();
    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(!(localStorage.getItem('licenseIsValid') === 'true' && resultsCtx.agreeToTerms));
    const [profession, setProfession] = useState<string>(resultsCtx.profession);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(resultsCtx.agreeToTerms);

    const doctorsRegex = [/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/];
    const nursesRegex = [/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[A-Za-z]/];
    const pharmacistsRegex = [/[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/, ' ', /[0-9]/];

    const licenseInputHandler = (event: ChangeEvent<HTMLInputElement>): void => {

        const licenseNumber = prepareLicenseNumberFormat(event);
        setLicenseNumber(licenseNumber);
        resultsCtx.onLicenseNumberChange(licenseNumber);

        if (validateDoctorsMedicalLicenseNumber(licenseNumber) || validateNursesMedicalLicenseNumber(licenseNumber) || validatePharmacistsLicenseNumber(licenseNumber)) {
            localStorage.setItem('licenseIsValid', 'true');
            if (agreeToTerms) setLoginButtonDisabled(false);
        } else {
            localStorage.setItem('licenseIsValid', 'false');
            setLoginButtonDisabled(true);
        }
    }

    const selectProfessionHandler: (profession: string) => void = (profession: string): void => {
        setProfession(profession);
        setLicenseNumber('');
        resultsCtx.onProfessionChange(profession);
        resultsCtx.onLicenseNumberChange('');
        setLoginButtonDisabled(true);
        localStorage.setItem('licenseIsValid', 'false');
        localStorage.setItem('loginButtonClicked', 'false');
    }

    const checkboxHandler: (state: boolean) => void = (state: boolean): void => {

        setAgreeToTerms(state);
        resultsCtx.onAgreeToTermsChange(state);
        localStorage.setItem('agreeToTerms', state ? 'true' : 'false');

        if (state) {
            if ((profession === Profession.doctor && validateDoctorsMedicalLicenseNumber(resultsCtx.licenseNumber)) ||
                (profession === Profession.nurse && validateNursesMedicalLicenseNumber(resultsCtx.licenseNumber)) ||
                (profession === Profession.pharmacist && validatePharmacistsLicenseNumber(resultsCtx.licenseNumber))) {
                setLoginButtonDisabled(false);
            }
        } else {
            setLoginButtonDisabled(true);
        }
    }

    const loginButtonHandler: () => void = (): void => {
        localStorage.setItem('loginButtonClicked', 'true');
        navigate('/birth-date');
    }

    const getProfessionRegex = (profession: string): (RegExp | string)[] | undefined => {
        switch (profession) {
            case Profession.doctor:
                return doctorsRegex;
            case Profession.nurse:
                return nursesRegex
            case Profession.pharmacist:
                return pharmacistsRegex;
        }
    }

    const getPlaceholder: (profession: string) => string = (profession: string): string => {
        switch (profession) {
            case Profession.doctor:
                return '_ _ _ _ _ _ _';
            case Profession.nurse:
                return '_ _ _ _ _ _ _ _';
            case Profession.pharmacist:
                return '_ _ _ _ _ _ _ _';
            default:
                return '';
        }
    }

    useEffect((): void => {
    }, [
        licenseNumber,
        loginButtonDisabled,
        profession,
        agreeToTerms
    ]);

    return (
        <div className="login__main-wrapper">

            <div className="login__inner-wrapper">

                <div className="login_label-input__wrapper">
                    <div className="login_label__wrapper">
                        <div className="login_label__inner-wrapper">
                            Jestem:
                        </div>
                    </div>
                    <div className="login_input__wrapper">
                        <div className="login_select__wrapper">
                            <SelectComponent value={profession}
                                             showChooseOption={false}
                                             onValueChange={selectProfessionHandler}
                                             data={[Profession.doctor, Profession.nurse, Profession.pharmacist]}/>
                        </div>
                    </div>
                </div>

                <div className="login_label-input__wrapper">
                    <div className="login_label__wrapper">
                        <div className="login_label__inner-wrapper">
                            Numer PWZ:
                        </div>
                    </div>
                    <div className="login_input__wrapper">
                        <MedicalLicenseNumberInput
                            value={stringNotNull(resultsCtx.licenseNumber) ? resultsCtx.licenseNumber : licenseNumber}
                            mask={getProfessionRegex(profession)}
                            placeholder={getPlaceholder(profession)}
                            onChange={licenseInputHandler}/>
                    </div>
                </div>
                <div className="login_claim__wrapper">
                    Wpisując numer wykonywania zawodu oświadczam, że z zawodu
                    jestem {profession}.
                </div>

                <div className="login_checkbox__wrapper">
                    <CheckboxComponent id="loginRegulationsAgreementCheckbox"
                                       value="checkboxValue"
                                       showRegulationsValue={true}
                                       onCheckHandler={checkboxHandler}
                                       checked={agreeToTerms}/>
                </div>

                <div className="login_button__wrapper">
                    <ButtonComponent id="loginButton"
                                     value="wejdź >>"
                                     className="login-button"
                                     disabled={loginButtonDisabled}
                                     onClick={loginButtonHandler}/>
                </div>
            </div>
        </div>
    );

}

export default Login;
