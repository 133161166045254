import React from 'react';
import {Link} from 'react-router-dom';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import './checkbox.css';
import {notNull} from '../../../utils/null-check';

type CheckboxProps = {
    id: string;
    value: string;
    textValue?: string;
    useLightColor?: boolean;
    showRegulationsValue?: boolean;
    onCheckHandler: (event: any) => void;
    checked: boolean;
    disabled?: boolean;
}

const CheckboxComponent: React.FC<CheckboxProps> = (props) => {
    return (
        <div className="checkbox_main-wrapper">
            <Checkbox.Root id={props.id}
                           className={props.useLightColor ? "checkbox-root__light" : "checkbox-root"}
                           data-testid="checkboxTestId"
                           value={props.value}
                           onCheckedChange={props.onCheckHandler}
                           checked={props.checked}
                           disabled={notNull(props.disabled) ? props.disabled : false}>
                <Checkbox.Indicator className="checkbox-indicator">
                    <Check size={12}/>
                </Checkbox.Indicator>
            </Checkbox.Root>
            {props.textValue &&
                <label className={props.useLightColor ? "checkbox-label__light" : "checkbox-label"} htmlFor={props.id}>
                    {props.textValue}
                </label>
            }
            {props.showRegulationsValue &&
                <label className="checkbox-regulations-label" htmlFor={props.id}>
                    Zgadzam się z <Link className="checkbox_regulations-text" to={"/regulations"}>regulaminem</Link>.
                </label>
            }
        </div>
    );
}

export default CheckboxComponent;
