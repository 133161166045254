import { FactorsVaccinationsModel } from '../../model/factors-vaccinations.model';
import { mapVaccinations } from './../../utils/map-vaccinations';
import { TFunction } from 'i18next';

const factorsVaccinationsData = (t: TFunction): FactorsVaccinationsModel => {
  return {
    birthDate_less_than_18: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    birthDate_18_to_49: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    birthDate_50_to_54: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'HZV*', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    birthDate_55_to_59: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'HZV*', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    birthDate_60_to_64: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['RSV', 'SP', 'HZV', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR HD'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    birthDate_over_65: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['RSV', 'HZV', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR HD', 'SP'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    pregnancy: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['RSVc'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GRc', 'DTPc*'], t),
      contraindicatedVaccinations: mapVaccinations(['MMR', 'OW', 'HPV', 'HZV'], t)
    },
    plannedPregnancy: {
      mandatoryVaccinations: mapVaccinations(['WB*'], t),
      recommendedVaccinations: mapVaccinations(['MMR*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'OW*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    circulatorySystemDisease: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    diabetes: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    chronicRespiratoryDisease: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    chronicKidneyDisease: {
      mandatoryVaccinations: mapVaccinations(['WB*'], t),
      recommendedVaccinations: mapVaccinations(['SP', 'WB*', 'NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    chronicLiverDisease: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    viralHepatitisC: {
      mandatoryVaccinations: mapVaccinations(['WB*'], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations(['HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    depression: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations(['HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    neoplasticDisease: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP*', 'NM', 'WB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    spleenDysfunction: {
      mandatoryVaccinations: mapVaccinations(['DTP', 'HiB', 'SP', 'MMR', 'PO', 'WB'], t),
      recommendedVaccinations: mapVaccinations(['SP', 'NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    immuneDisorders: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'HiB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    immunosuppressiveDrugs: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'HiB*'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR', 'HZV*'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    hivInfection: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP', 'NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    boneMarrowTransplantationBefore: {
      mandatoryVaccinations: mapVaccinations(['DTP', 'HiB', 'SP', 'MMR', 'PO', 'WB'], t),
      recommendedVaccinations: mapVaccinations(['NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    boneMarrowTransplantationAfter: {
      mandatoryVaccinations: mapVaccinations(['DTP', 'HiB', 'SP', 'MMR', 'PO', 'WB'], t),
      recommendedVaccinations: mapVaccinations(['NM'], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    organTransplantationBefore: {
      mandatoryVaccinations: mapVaccinations(['DTP', 'HiB', 'SP', 'MMR', 'PO', 'WB'], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    organTransplantationAfter: {
      mandatoryVaccinations: mapVaccinations(['DTP', 'HiB', 'SP', 'MMR', 'PO', 'WB'], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    cochlearImplantation: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    cerebroSpinalFluidLeak: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    autoimmuneDisease: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations(['HZV*'], t)
    },
    medicalStudentHealthcare: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['DTP'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    neonatologyPediatricsWorker: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations(['WA', 'DTP'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    infectiousDiseasesGastroenterologyWorker: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['DTP'], t),
      highlyRecommendedVaccinations: mapVaccinations(['WA'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    workingWithSevereImmunodeficiency: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    policeman: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    fireFighter: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['KZM'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    borderGuard: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['KZM'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    army: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['KZM'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    healthCareHomeResident: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations(['GR'], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    otherMultiPersonPlace: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['MMR*'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    outdoorActivity: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['KZM'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    hepatitisBExposure: {
      mandatoryVaccinations: mapVaccinations(['WB*'], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    cigarettesAlcoholAbuse: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations(['SP'], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    },
    travelingPlans: {
      mandatoryVaccinations: mapVaccinations([], t),
      recommendedVaccinations: mapVaccinations([], t),
      highlyRecommendedVaccinations: mapVaccinations([], t),
      contraindicatedVaccinations: mapVaccinations([], t)
    }
  };
};

export default factorsVaccinationsData;
