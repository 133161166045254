import React, {useContext, useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import './formbirthdate.css';
import {ConfigProvider, DatePicker, Form } from 'antd';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {Locale} from 'antd/es/locale';
import SwitchComponent from '../../../../shared/switch/Switch.component';
import ResultsContext from '../../../../../store/results-context';
import ButtonComponent from '../../../../shared/button/Button.component';
import dayjs from 'dayjs';
import {ResultsModel} from '../../../../../model/results.model';


interface FormBirthDateProps {
    t: (key: string) => string;
    currentLocale: Locale;
}

const FormBirthDate: React.FC<FormBirthDateProps> = ({t, currentLocale}: FormBirthDateProps) => {

    const [form] = Form.useForm();

    const navigate: NavigateFunction = useNavigate();

    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [goNextButtonDisabled, setGoNextButtonDisabled] = useState<boolean>(true);

    const goNextButtonText: string = t('go_next_button');
    const goBackButtonText: string = t('go_back_button');

    const goBackButtonHandler: () => void = (): void => {
        navigate('/');
    }

    const goNextButtonHandler: () => void = (): void => {
        form
            .validateFields()
            .then(() => {
                navigate('/civilization-diseases');
            })
            .catch((errorInfo) => {});
    };

    useEffect(() => {
        setErrorMessage(t('screen_birth_date_age_error'));

        if (resultsCtx.dateOfBirth) {
            form.setFieldsValue({
                birthDate: dayjs(resultsCtx.dateOfBirth)
            });

            form.validateFields()
                .then(() => setGoNextButtonDisabled(false))
                .catch(() => setGoNextButtonDisabled(true));
        }

    }, [resultsCtx.dateOfBirth, t, form, errorMessage, goNextButtonDisabled]);


    return (

        <div className="form-birth-date__wrapper">

            <div className="label-input__wrapper">
                <div className="question__wrapper birth-date-question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_birth_date')}
                    </div>

                    <div className="birth-date-input__wrapper">

                        <Form form={form} layout="vertical" onFinish={goNextButtonHandler}>
                            <ConfigProvider
                                theme={{ token: { colorPrimary: '#bdbdbd' } }}
                                locale={currentLocale}>
                                <Form.Item
                                    name="birthDate"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value && value.isBefore(dayjs().subtract(18, 'years'))
                                                    ? Promise.resolve()
                                                    : Promise.reject(errorMessage),
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        size={'large'}
                                        value={resultsCtx.dateOfBirth ? dayjs(resultsCtx.dateOfBirth) : null}
                                        onChange={(date) => resultsCtx.onDateOfBirthChange(date ? date.toDate() : null)}
                                        format="YYYY-MM-DD"
                                        allowClear={false}
                                        showToday={false}
                                        inputReadOnly={true}
                                    />
                                </Form.Item>
                            </ConfigProvider>
                        </Form>

                    </div>

                </div>
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="pregnancy"
                                         onCheckHandler={resultsCtx.onPregnancyChange}
                                         checked={resultsCtx.pregnancy}/>
                    </div>
                </div>
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_planned_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="plannedPregnancy"
                                         onCheckHandler={resultsCtx.onPlannedPregnancyClick}
                                         checked={resultsCtx.plannedPregnancy}/>
                    </div>
                </div>

                <div className="navigation-buttons__wrapper">

                    <ButtonComponent id="goBackButton"
                                     value={goBackButtonText}
                                     className="navigation-button"
                                     onClick={goBackButtonHandler}/>

                    <ButtonComponent id="goNextButton"
                                     value={goNextButtonText}
                                     disabled={goNextButtonDisabled}
                                     className="navigation-button"
                                     onClick={goNextButtonHandler}/>

                </div>

            </div>

        </div>
    )
}

export default FormBirthDate;
