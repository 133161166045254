import React, {useContext} from 'react';
import './mainscreen.css';
import { House, Info, BookType, MessageCircleQuestion, Newspaper, LogOut } from 'lucide-react';
import {Outlet, useNavigate} from 'react-router-dom';
import {Locale} from 'antd/es/locale';
import enUS from 'antd/lib/locale/en_US';
import plPL from 'antd/lib/locale/pl_PL';
import PopoverComponent from '../../shared/popover/Popover.component';
import ResultsContext from '../../../store/results-context';
import {ResultsModel} from '../../../model/results.model';


interface MainScreenProps {
    t: (key: string) => string;
    changeLanguage: (language: string) => void;
    changeCurrentLocale: (locale: Locale) => void;
}


const MainScreen: React.FC<MainScreenProps> = ({t, changeLanguage, changeCurrentLocale}) => {

    const navigate = useNavigate();
    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const logoutHandler: () => void = (): void => {
        resultsCtx.onLicenseNumberChange('');
        resultsCtx.onAgreeToTermsChange(false);
        localStorage.setItem('licenseIsValid', 'false');
        localStorage.setItem('loginButtonClicked', 'false');
        localStorage.setItem('agreeToTerms', 'false');
    }

    return (
        <div className="main-screen__wrapper">
            <div className="top-bar__wrapper">
                <div className="menu-home__wrapper" onClick={() => navigate('/')}>
                    <House color="var(--orange)" size={24} strokeWidth={1.4}/>
                </div>
                <div className="menu-text__wrapper">
                    <div className="menu-item__wrapper" onClick={() => navigate('/about')}>
                        <Info color="var(--orange)" size={20} strokeWidth={1.4}/> {t('menu_project')}
                    </div>
                    {/*|*/}
                    {/*<div className="menu-item__wrapper">*/}
                    {/*    <CalendarDays color="var(--orange)" size={20} strokeWidth={1.4} /> {t('menu_calendar')}*/}
                    {/*</div>*/}
                    |
                    <div className="menu-item__wrapper" onClick={() => navigate('/brochures')}>
                        <BookType color="var(--orange)" size={20} strokeWidth={1.4}/> {t('menu_brochures')}
                    </div>
                    |
                    <div className="menu-item__wrapper" onClick={() => navigate('/qanda')}>
                        <MessageCircleQuestion color="var(--orange)" size={20} strokeWidth={1.4}/> {t('menu_qanda')}
                    </div>
                    |
                    <div className="menu-item__wrapper" onClick={() => navigate('/news')}>
                        <Newspaper color="var(--orange)" size={20} strokeWidth={1.4}/> {t('menu_news')}
                    </div>
                </div>
                <div className="language-bar__wrapper">

                    <img className="flag__image"
                         alt="język polski"
                         onClick={() => {
                             changeLanguage('pl');
                             changeCurrentLocale(plPL);
                         }}
                         src={require('../../../assets/graphics/flagPL.png')}/>

                    <img className="flag__image"
                         alt="english version"
                         onClick={() => {
                             changeLanguage('en');
                             changeCurrentLocale(enUS);
                         }}
                         src={require('../../../assets/graphics/flagEN.png')}/>
                </div>

                <div className="logout__wrapper" onClick={() => logoutHandler()}>
                    {t('logout_text')}
                    <LogOut color="var(--orange)" size={20} strokeWidth={1.4}/>
                </div>
            </div>

            <div className="content__wrapper">
                <Outlet/>
            </div>

            <div className="main-screen_footer__wrapper">
                <PopoverComponent/>
            </div>
        </div>
    )

}

export default MainScreen;
