import { useContext, useMemo } from 'react';
import ResultsContext from '../store/results-context';
import {calculateAge} from '../utils/calculate-age';
import {addVaccinations} from '../utils/add-vaccinations';
import {removeContraindicatedVaccinations} from '../utils/remove-contraindicated-vaccinations';
import {MergedResultsModel} from '../model/merged-results.model';
import {VaccineModel} from '../model/vaccine.model';
import {deduplicate} from '../utils/deduplicate-vaccinations';
import {useTranslation} from 'react-i18next';
import {subtractRepeatedVaccinations} from '../utils/subtract-repeated-vaccinations';

export const useMergedResults = () => {

    const resultsContext = useContext(ResultsContext);
    const { t } = useTranslation();

    const mergedData = useMemo(() => {

        let mandatoryVaccinations: VaccineModel[] = [];
        let recommendedVaccinations: VaccineModel[] = [];
        let highlyRecommendedVaccinations: VaccineModel[] = [];
        let contraindicatedVaccinations: VaccineModel[] = [];

        const conditions: (keyof MergedResultsModel)[] = [
            'pregnancy',
            'plannedPregnancy',
            'circulatorySystemDisease',
            'diabetes',
            'chronicRespiratoryDisease',
            'chronicKidneyDisease',
            'chronicLiverDisease',
            'viralHepatitisC',
            'depression',
            'neoplasticDisease',
            'spleenDysfunction',
            'immuneDisorders',
            'immunosuppressiveDrugs',
            'hivInfection',
            'boneMarrowTransplantationBefore',
            'boneMarrowTransplantationAfter',
            'organTransplantationBefore',
            'organTransplantationAfter',
            'cochlearImplantation',
            'cerebroSpinalFluidLeak',
            'autoimmuneDisease',
            'medicalStudentHealthcare',
            'neonatologyPediatricsWorker',
            'infectiousDiseasesGastroenterologyWorker',
            'workingWithSevereImmunodeficiency',
            'policeman',
            'fireFighter',
            'borderGuard',
            'army',
            'healthCareHomeResident',
            'otherMultiPersonPlace',
            'outdoorActivity',
            'hepatitisBExposure',
            'cigarettesAlcoholAbuse',
            'travelingPlans',
        ];

        const ageRange: string = calculateAge(resultsContext.dateOfBirth);
        addVaccinations(
            mandatoryVaccinations,
            recommendedVaccinations,
            highlyRecommendedVaccinations,
            contraindicatedVaccinations,
            ageRange,
            t
        );

        conditions.forEach((condition) => {
            if (resultsContext[condition]) {
                addVaccinations(
                    mandatoryVaccinations,
                    recommendedVaccinations,
                    highlyRecommendedVaccinations,
                    contraindicatedVaccinations,
                    condition,
                    t
                );
            }
        });

        contraindicatedVaccinations = deduplicate(contraindicatedVaccinations);
        mandatoryVaccinations = deduplicate(mandatoryVaccinations);
        highlyRecommendedVaccinations = deduplicate(highlyRecommendedVaccinations);
        recommendedVaccinations = deduplicate(recommendedVaccinations);

        mandatoryVaccinations = removeContraindicatedVaccinations(mandatoryVaccinations, contraindicatedVaccinations);
        highlyRecommendedVaccinations = removeContraindicatedVaccinations(highlyRecommendedVaccinations, contraindicatedVaccinations);
        recommendedVaccinations = removeContraindicatedVaccinations(recommendedVaccinations, contraindicatedVaccinations);

        highlyRecommendedVaccinations = subtractRepeatedVaccinations(highlyRecommendedVaccinations, mandatoryVaccinations);
        recommendedVaccinations = subtractRepeatedVaccinations(recommendedVaccinations, mandatoryVaccinations);
        recommendedVaccinations = subtractRepeatedVaccinations(recommendedVaccinations, highlyRecommendedVaccinations);

        return {
            mandatoryVaccinations,
            recommendedVaccinations,
            highlyRecommendedVaccinations,
            contraindicatedVaccinations
        };
    }, [resultsContext, t]);

    return mergedData;
};
