import React, {useContext} from 'react';
import { Users, BriefcaseMedical } from 'lucide-react';
import './questionnairechoice.css';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import ResultsContext from '../../../../store/results-context';
import {ResultsModel} from '../../../../model/results.model';

interface QuestionnaireChoiceProps {
    t: (key: string) => string;
}

const QuestionnaireChoice: React.FC<QuestionnaireChoiceProps> = ({t}: QuestionnaireChoiceProps) => {

    const navigate: NavigateFunction = useNavigate();

    const resultsCtx: ResultsModel = useContext(ResultsContext);

    const professionalVerified: boolean = localStorage.getItem('loginButtonClicked') === 'true';

    return (
        <div className="questionnaire-choice-main__wrapper">
            <div className="box__wrapper" onClick={() =>
            {
                resultsCtx.onShowResultsForProfessionals(professionalVerified);
                professionalVerified ? navigate('/birth-date') : navigate('/login');
            }
            }>
                {t('menu_for_professionals')} <BriefcaseMedical size={60} strokeWidth={1} />
            </div>
            <div className="box__wrapper" onClick={() => {
                resultsCtx.onShowResultsForProfessionals(false);
                navigate('/birth-date');
            }
            }>
                {t('menu_for_patients')} <Users size={60} strokeWidth={1} />
            </div>
        </div>
    )

}

export default QuestionnaireChoice;
